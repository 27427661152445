import './App.css';

const RecentlyAdded = () => {
  const items = [
      { name: 'IVEX Financial', symbol: 'IVEX', price: 0.6414 },
      { name: 'BARRON', symbol: 'BARRON', price: 0.0000000000868 },
      { name: 'Brett', symbol: 'BRETT', price: 0.04883 },
  ];

  return (
      <div className="bg-white rounded-lg shadow-md p-4">
          <h2 className="text-lg font-semibold mb-2">Recently Added</h2>
          <div className="grid grid-cols-3 gap-4">
              {items.map((item, index) => (
                  <div key={index} className="flex items-center">
                      <div className="w-8 h-8 rounded-full bg-gray-300 flex items-center justify-center mr-2">
                          <span className="text-sm font-semibold">{item.symbol.charAt(0)}</span>
                      </div>
                      <div>
                          <p className="text-sm font-semibold">{item.name}</p>
                          <p className="text-xs text-gray-500">${item.price.toFixed(4)}</p>
                      </div>
                  </div>
              ))}
          </div>
      </div>
  );
};

const TopCommunityAccounts = () => {
  const accounts = [
      { name: 'Rekt Capital', handle: '@rektcapital', image: 'https://placehold.co/32x32' },
      { name: 'Satoshi_Club', handle: '@SatoshiClub', image: 'https://placehold.co/32x32' },
  ];

  return (
      <div className="bg-white rounded-lg shadow-md p-4">
          <h2 className="text-lg font-semibold mb-2">Top Community Accounts</h2>
          <div className="flex items-center">
              {accounts.map((account, index) => (
                  <div key={index} className="flex items-center mr-4">
                      <img src={account.image} alt="Profile picture of a person with short brown hair and a blue shirt" className="w-8 h-8 rounded-full mr-2" />
                      <div>
                          <p className="text-sm font-semibold">{account.name}</p>
                          <p className="text-xs text-gray-500">{account.handle}</p>
                      </div>
                      <button className="text-blue-500 hover:text-blue-700 ml-2">
                          <i className="fas fa-plus"></i> Follow
                      </button>
                  </div>
              ))}
          </div>
      </div>
  );
};

const FearAndGreedIndex = () => {
  return (
      <div className="bg-white rounded-lg shadow-md p-4 flex items-center justify-between">
          <div>
              <p className="text-sm text-gray-500">Fear & Greed Index</p>
              <p className="text-3xl font-bold">68</p>
          </div>
          <div className="w-32 h-20 rounded-full bg-gradient-to-r from-yellow-400 to-green-500 flex items-center justify-center">
              <div className="w-6 h-6 rounded-full bg-black"></div>
          </div>
      </div>
  );
};

const CryptoTable = () => {
  const currencies = [
      {
          name: 'Bitcoin',
          symbol: 'BTC',
          price: 69820.37,
          change1h: 0.73,
          change24h: 1.91,
          change7d: -12.82,
          marketCap: 1375575398537,
          volume24h: 40096859492,
          circulatingSupply: 19701634,
          image: `${process.env.PUBLIC_URL}/bitcoin.svg`,
      },
      {
          name: 'Ethereum',
          symbol: 'ETH',
          price: 3755.59,
          change1h: 0.53,
          change24h: -3.09,
          change7d: -29.36,
          marketCap: 451153079264,
          volume24h: 33352986005,
          circulatingSupply: 120128511,
          image: `${process.env.PUBLIC_URL}/ethereum.svg`,
      },
      {
          name: 'Tether',
          symbol: 'USDT',
          price: 0.9999,
          change1h: -0.01,
          change24h: -0.01,
          change7d: 0.03,
          marketCap: 111351067019,
          volume24h: 92835983345,
          circulatingSupply: 111364216399,
          image: `${process.env.PUBLIC_URL}/tether.png`,
      },
      // Add more currencies as needed
  ];

  return (
      <div className="bg-white rounded-lg shadow-md p-4">
          <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-semibold">Today's Cryptocurrency Prices by CloudCrypto</h2>
              <div className="flex items-center">
                  <span className="text-sm text-gray-500 mr-2">Highlights</span>
                  <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input type="checkbox" name="toggle" id="toggle" className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer" />
                      <label htmlFor="toggle" className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                  </div>
              </div>
          </div>
          <div className="overflow-x-auto">
              <table className="w-full table-auto">
                  <thead>
                      <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                          <th className="py-3 px-6 text-left">#</th>
                          <th className="py-3 px-6 text-left">Name</th>
                          <th className="py-3 px-6 text-right">Price</th>
                          <th className="py-3 px-6 text-right">1h %</th>
                          <th className="py-3 px-6 text-right">24h %</th>
                          <th className="py-3 px-6 text-right">7d %</th>
                          <th className="py-3 px-6 text-right">Market Cap</th>
                          <th className="py-3 px-6 text-right">Volume(24h)</th>
                          <th className="py-3 px-6 text-right">Circulating Supply</th>
                          <th className="py-3 px-6 text-right"></th>
                      </tr>
                  </thead>
                  <tbody className="text-gray-600 text-sm font-light">
                      {currencies.map((currency, index) => (
                          <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                  <div className="flex items-center">
                                      <span className="font-medium">{index + 1}</span>
                                  </div>
                              </td>
                              <td className="py-3 px-6 text-left whitespace-nowrap">
                                  <div className="flex items-center">
                                      <img src={currency.image} alt={`${currency.name} logo`} className="w-6 h-6 mr-2" />
                                      <span>{currency.name} <span className="text-gray-400">({currency.symbol})</span></span>
                                  </div>
                              </td>
                              <td className="py-3 px-6 text-right">${currency.price.toFixed(2)}</td>
                              <td className={`py-3 px-6 text-right ${currency.change1h >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                  {currency.change1h >= 0 ? '+' : ''}{currency.change1h.toFixed(2)}%
                              </td>
                              <td className={`py-3 px-6 text-right ${currency.change24h >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                  {currency.change24h >= 0 ? '+' : ''}{currency.change24h.toFixed(2)}%
                              </td>
                              <td className={`py-3 px-6 text-right ${currency.change7d >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                                  {currency.change7d >= 0 ? '+' : ''}{currency.change7d.toFixed(2)}%
                              </td>
                              <td className="py-3 px-6 text-right">${currency.marketCap.toLocaleString()}</td>
                              <td className="py-3 px-6 text-right">${currency.volume24h.toLocaleString()}</td>
                              <td className="py-3 px-6 text-right">{currency.circulatingSupply.toLocaleString()} {currency.symbol}</td>
                              <td className="py-3 px-6 text-right">
                                  <div className="flex justify-end">
                                      <div className={`w-4 h-4 rounded-full ${currency.change24h >= 0 ? 'bg-green-500' : 'bg-red-500'}`}></div>
                                  </div>
                              </td>
                          </tr>
                      ))}
                  </tbody>
              </table>
          </div>
      </div>
  );
};

const App = () => {
  return (
      <div className="max-w-7xl mx-auto px-4 py-8">
          <nav className="flex items-center justify-between mb-8">
              <div className="flex items-center">
                  <img src={`${process.env.PUBLIC_URL}/icon.png`} alt="CloudCrypto logo" width="48" height="48" className="mr-2" />
                  <span className="text-xl font-semibold">CloudCrypto</span>
              </div>
              <div className="flex items-center">
                  <a href="#" className="text-gray-600 hover:text-gray-800 mx-2">Cryptocurrencies</a>
                  <a href="#" className="text-gray-600 hover:text-gray-800 mx-2">Exchanges</a>
                  <a href="#" className="text-gray-600 hover:text-gray-800 mx-2">Community</a>
                  <a href="#" className="text-gray-600 hover:text-gray-800 mx-2">Products</a>
                  <a href="#" className="text-gray-600 hover:text-gray-800 mx-2">Learn</a>
                  <div className="ml-4">
                      <a href="#" className="text-gray-600 hover:text-gray-800 mx-2">
                          <i className="fas fa-star"></i>
                      </a>
                      <a href="#" className="text-gray-600 hover:text-gray-800 mx-2">
                          <i className="fas fa-briefcase"></i>
                      </a>
                  </div>
                  <div className="ml-4 relative">
                      <input type="text" placeholder="Search" className="px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500" />
                      <i className="fas fa-search absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400"></i>
                  </div>
                  <div className="ml-4">
                      <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Log In</button>
                      <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow ml-2">Sign Up</button>
                  </div>
              </div>
          </nav>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div>
                  <RecentlyAdded />
              </div>
              <div>
                  <TopCommunityAccounts />
              </div>
              <div>
                  <FearAndGreedIndex />
              </div>
          </div>
          <div className="mt-8">
              <CryptoTable />
          </div>
      </div>
  );
};

export default App;
